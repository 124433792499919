/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../services/axios';

import { createAxiosObject, setAccessToken } from './_common';

const findLeads = async params => {
  const axios = createAxiosObject();

  const url = `inquiries?page=1&search=`;

  const { data } = await axios.get(url);

  return { ...data };

};

const registerUser = async params => {
  const axios = createAxiosObject();

  const url = `/register`;

  params.phone = params.phone.replaceAll('-','');

  const responce = await axios.post(url, params);

  const { status, message, data } = responce.data;

  if (status === 'success') {
    setAccessToken(data.access_token);
  }

  return { status, message };
};

const getCountriesList = async () => {
  const axios = createAxiosObject();

  const url = `/auth/country-codes`;

  const { status, data } = await axios.get(url);

  return { status, data };
};

const getCountriesListByUser = async () => {
  const axios = createAxiosObject();

  const url = `settings/country-codes`;

  const { status, data } = await axios.get(url);

  return { status, data };
};

const getConfigurationInfo = async () => {
  const axios = createAxiosObject();

  const url = `/settings/integrations`;

  const { status, data } = await axios.get(url);

  return data;
};

const testCall = async params => {
  const axios = createAxiosObject();

  const request = {
    lead_id: params?.lead.id,
    rep_phone: params?.phoneNumber,
  };

  const url = `/calls/test`;

  try {
    const { data } = await axios.post(url, request);
    const { status, message } = data;

    return { status, data, message };
  } catch (error) {
    return { status: 'error', data: [], message: 'Unauthorized.' };
  }
};

export default { findLeads, registerUser, getCountriesList, getCountriesListByUser, getConfigurationInfo, testCall };
