/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';

import ParsingIcon from 'src/assets/icons/parsing.svg';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  logoWrapper: {
    height: 42,
    width: 220,
  },
  spacer: {
    margin: theme.spacing(0, 2),
  },
  text: {
    fontSize: 25,
  },
  infoSection: {
    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.08)',
  },
  button: {
    width: 150,
    height: 53,
  },
  buttonWrapper: {
    padding: theme.spacing(4, 0),
  },
  subtitle: {
    fontSize: 13,
  },
  emailText: {
    fontFamily: 'Lato',
    fontSize: 18,
    padding: theme.spacing(3, 0),
    wordWrap: 'break-word',
  },
}));

export default function EmailPopup({ configurations, parentClasses, close, setConfiguration }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <ParsingIcon className={classes.spacer} />

        <Typography variant="h4" align="center" className={classes.text}>
          Email Parsing
        </Typography>
      </div>

      <Typography variant="subtitle1" align="center">
        Please add your Leverly account email address to the distribution list for your lead source.
      </Typography>

      <div>
        <Typography variant="h4" align="center" className={classes.emailText}>
          {configurations.mail2phone}
        </Typography>
      </div>

      <Typography variant="subtitle1" align="center" className={classes.subtitle}>
        The emails should have each field name and value pair on a separate line. Field names should be separated from
        values with a valid separator (comma, colon, semi-colon, or equal sign). A phone number and first name are the
        minimum requirements.
      </Typography>

      <div className={classes.buttonWrapper}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button fullWidth variant="outlined" className={classes.button} onClick={close}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => {
                setConfiguration({ config: 'email' });
                close();
              }}
            >
              Test now
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
