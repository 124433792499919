
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  makeStyles,
  Grid,
  Typography,
  Link,
  Button,
  Divider,
  Box,
  Dialog,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import HotglueConfig, { Connections } from '@hotglue/widget';
import { IntegrationAppProvider } from '@integration-app/react';

import { currentStep, setConfiguration, nextStep, previousStep, skipStep } from 'src/features/onboarding/slice';
import StepsNavigation, { NextButton, SkipButton, BackButton } from 'src/features/onboarding/stepsNavigation';
import Paper from 'src/components/paper';

import ZapierIcon from 'src/assets/icons/zapier.svg';
import ParsingIcon from 'src/assets/icons/parsing.svg';
import HttpIcon from 'src/assets/icons/http.svg';
import SalesforceIcon from 'src/assets/icons/salesforce.svg';
import MsIcon from 'src/assets/icons/ms.svg';
import NativeIcon from 'src/assets/icons/native.svg';


import api from 'src/api/onboarding';

import ZapierPopup from './zapierPopup';
import EmailPopup from './emailPopup';
import HttpPopup from './httpPopup';
import SalesforcePopup from './salesforcePopup';
import MsPopup from './MsPopup';
import NativePop from './nativePop';
import ConfigurationInfo from './configurationInfo';
import { IntegrationsList } from 'src/features/integrations/components/IntegrationsList';

const useStyles = makeStyles(theme => ({
  scroll: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2),
    },
  },
  container: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 700,
    margin: 'auto',
    position: 'relative',
  },
  containerH2: {
    fontWeight: '700',
    fontFamily: 'Lato',
    fontSize: '16px',
    marginBottom: '24px',
  },
  main: {
    marginTop: '24px',
  },
  card: {
    height: 173,
    maxWidth: 173,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(3, 0, 2),
    height: 45,
  },
  logo: {
    // margin: theme.spacing(2),
  },
  infoTitle: {
    padding: theme.spacing(0, 4),
  },
  infoSection: {
    borderRadius: 5,
    width: '100%',
  },
  infoLine: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    minHeight: 60,
    maxWidth: '600px',
    overflowWrap: 'break-word',
  },
  textWrapper: {
    flexGrow: 1,
    padding: theme.spacing(0, 1),
  },
  button: {
    height: 43,
  },
  dialog: {
    marginLeft: 400,
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& .MuiDialog-paper': {
      // padding: theme.spacing(0, 9, 3),
      width: 620,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(3),
  },
  dialogContent: {
    padding: theme.spacing(0, 9, 2),
  },
  unselected: {
    filter: 'grayscale(100%)',
  },
  selectedButton: {
    color: '#00C379',
  },
  wrapper: {
    justifyContent: 'center',
  },
  hotglueWrap: {
    '& .hg-flows-container': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-flow-title': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-flow-subtitle': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-connector-name': {
      fontWeight: 400,
      fontSize: '16px',
    },
  },
}));

const DialogPopup = ({ open, close, children, setConfiguration }) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" open={open} className={classes.dialog}>
      <div className={classes.header}>
        <IconButton onClick={close}>
          <CloseIcon color="primary" />
        </IconButton>
      </div>

      <div className={classes.dialogContent}>{children({ close, setConfiguration })}</div>
    </Dialog>
  );
};

const Step3 = ({ configuration, setConfiguration, ...props }) => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const [configurationListData, setConfigurationListData] = useState(false);

  const handleOpen = event => {
    setOpenDialog(event.currentTarget.id);
  };

  const handleClose = event => {
    setOpenDialog(false);
  };

  const handlePrevious = () => {
    props.previousStep();
  };

  const handleSkip = () => {
    props.skipStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  useEffect(() => {
    api.getConfigurationInfo().then(({ status, data }) => {
      if (status === 'success') {
        setConfigurationListData(data);
      }
    });

    props.currentStep({ activeStep: 3 });
  }, []);

  // eslint-disable-next-line react/destructuring-assignment


  return (
    <>
      {/* <PerfectScrollbar> */}

      <div className={classes.scroll}>
        <div className={classes.container}>
          <Typography variant="h1">Connect your leads</Typography>
          <Typography className={classes.containerH2} variant="h2">Lead Source(s)</Typography>
          <Typography component="div" variant="subtitle1" paragraph>
            Choose the app(s) you use to capture and/or manage your leads. We can connect with anything. If you don’t see your app, we can help.
          </Typography>

          <div className={classes.main}>
            <Grid container className={classes.wrapper} alignItems="center" spacing={2}>
              {/* <HotglueConfig
                config={{
                  apiKey: 'wFNqSZ5oWchIq8pxZ8ew1eyeRtKf7Ra9cQigntKd',
                  envId: 'dev.hotglue.speak2leads.com'
                }}
              >
                <div className={classes.hotglueWrap}>
                  <Connections
                    tenant={
                      configurationListData.accountId?.substring(
                        configurationListData.accountId?.indexOf('_') + 1,
                        configurationListData.accountId?.length
                      )
                    }
                    multipleSources
                  />
                </div>
              </HotglueConfig> */}

              {
                configurationListData?.integration_app_token &&
                (<Paper>
                  <IntegrationAppProvider token={configurationListData.integration_app_token}>
                    <IntegrationsList data={configurationListData} />
                  </IntegrationAppProvider>
                </Paper>)
              }

              <Box marginTop={3} marginBottom={5}>
                <Link>Email info to your technical administrator or developer</Link>
              </Box>

              <ConfigurationInfo classes={classes} configurations={configurationListData} />
            </Grid>
          </div>
        </div>
      </div>

      {/* </PerfectScrollbar> */}

      <StepsNavigation>
        <BackButton onClick={handlePrevious} />

        <SkipButton onClick={handleSkip} />

        <NextButton
          onClick={handleNext}
        />
      </StepsNavigation>

      {!!openDialog && (
        <DialogPopup open close={handleClose} setConfiguration={setConfiguration}>
          {props => {
            switch (openDialog) {
              case 'zapier':
                return <ZapierPopup parentClasses={classes} configurations={configurationListData} {...props} />;
              case 'email': {
                return <EmailPopup parentClasses={classes} configurations={configurationListData} {...props} />;
              }
              case 'http': {
                return <HttpPopup parentClasses={classes} configurations={configurationListData} {...props} />;
              }
              case 'salesforce': {
                return <SalesforcePopup parentClasses={classes} configurations={configurationListData} {...props} />;
              }
              case 'ms': {
                return <MsPopup parentClasses={classes} configurations={configurationListData} {...props} />;
              }
              case 'native': {
                return <NativePop parentClasses={classes} configurations={configurationListData} {...props} />;
              }
              default:
                return null;
            }
          }}
        </DialogPopup>
      )}
    </>
  );
};

const mapDispatchToProps = { currentStep, setConfiguration, nextStep, previousStep, skipStep };

const mapStateToProps = ({ onboarding }) => {
  return { configuration: { ...onboarding.configuration } };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
