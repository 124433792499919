/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles, Typography, Grid, Button, FormControl, Link } from '@material-ui/core';

import Salesforce from 'src/assets/icons/salesforce.svg';

import ConfigurationInfo from './configurationInfo';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  logoWrapper: {
    height: 42,
    width: 220,
  },
  spacer: {
    margin: theme.spacing(0, 2),
  },
  text: {
    fontSize: 25,
  },
  infoSection: {
    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.08)',
  },
  button: {
    width: 150,
    height: 53,
  },
  buttonWrapper: {
    padding: theme.spacing(4, 0),
  },
  subtitle: {
    fontSize: 13,
  },
  emailText: {
    fontFamily: 'Lato',
    fontSize: 18,
    padding: theme.spacing(3, 0),
  },
}));

export default function ({ configurations, parentClasses, close, setConfiguration }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <Salesforce className={classes.spacer} />
        <Typography variant="h4" align="center" className={classes.text}>
          Salesforce
        </Typography>
      </div>

      <Typography variant="subtitle1" align="center" paragraph>
        Need help ? <br />
        Go to our <Link href="https://leverly.com/knowledge-base">knowledge base</Link> to get more information
      </Typography>

      <FormControl margin="normal" fullWidth>
        <ConfigurationInfo classes={parentClasses} configurations={configurations} />
      </FormControl>

      <div className={classes.buttonWrapper}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button fullWidth variant="outlined" className={classes.button} onClick={close}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => {
                setConfiguration({ config: 'salesforce' });
                close();
              }}
            >
              Test now
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
