import React from 'react';

import SEO from 'src/components/seo';

import Step3 from 'src/features/onboarding/steps/step3/index';

export default function Onboarding() {
  return (
    <>
      <SEO title="Step 3" />

      <Step3 />
    </>
  );
}
