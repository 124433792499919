/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function ConfigurationInfo({ configurations, classes }) {
  return (
    <Accordion className={classes.infoSection} elevation={0}>
      <AccordionSummary className={classes.infoTitle} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5"><b>Configuration info</b></Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid container direction="column">
            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>Account ID</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{configurations.accountId}</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>Username</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{configurations.username}</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>API key</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{configurations.token}</Typography>
              </Grid>
            </Grid>

            <Divider />

            {/* <Grid container className={classes.infoLine}>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Integration App Token</b>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>{integration_app_token}</Typography>
            </Grid>
          </Grid>

          <Divider /> */}

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>URL</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{configurations.url}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
