/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, Typography, Grid, Button, FormControl, Select, MenuItem } from '@material-ui/core';

import Native from 'src/assets/icons/native.svg';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  logoWrapper: {
    height: 42,
    width: 220,
  },
  spacer: {
    margin: theme.spacing(0, 2),
  },
  text: {
    fontSize: 25,
  },
  infoSection: {
    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.08)',
  },
  button: {
    width: 150,
    height: 53,
  },
  buttonWrapper: {
    padding: theme.spacing(4, 0),
  },
  subtitle: {
    fontSize: 13,
  },
  emailText: {
    fontFamily: 'Lato',
    fontSize: 18,
    padding: theme.spacing(3, 0),
  },
}));

export default function ({ close, setConfiguration }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <Native className={classes.spacer} />

        <Typography variant="h4" align="center" className={classes.text}>
          Native
        </Typography>
      </div>

      <Typography variant="subtitle1" align="center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      </Typography>

      <FormControl margin="normal" fullWidth>
        <Select value={0} variant="outlined">
          <MenuItem value={0}>
            <b>Unbounce</b>
          </MenuItem>
        </Select>
      </FormControl>

      <div className={classes.buttonWrapper}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button fullWidth variant="outlined" className={classes.button} onClick={close}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => {
                setConfiguration({ config: 'native' });
                close();
              }}
            >
              Test now
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
